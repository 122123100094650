/* TODO split into smaller files */
.form-grid {
	display: grid;
	column-gap: 0px;
	row-gap: 0px;
}

.form-inline-grid {
	display: inline-grid;
	column-gap: 0px;
	row-gap: 0px;
}

fieldset.oldfieldset {
	border: 1px groove #ddd !important;
	padding: 0 1.4em 1.4em 1.4em !important;
	margin: 1.5em !important;
	-webkit-box-shadow:  0px 0px 0px 0px #000;
	box-shadow:  0px 0px 0px 0px #000;
}

legend.oldlegend {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
	width:auto;
	padding:0 10px;
	border-bottom:none;
}
